@import 'vars'

.info-block 
  background-color: $info-block-color
  backdrop-filter: blur(4px)
  -webkit-backdrop-filter: blur(4px)
  padding: 1.3rem 1.8rem

  dt 
    font-size: 24px
    font-weight: 500

    @media (max-width: $md)
      font-size: 1.3rem

.section-img 
  position: absolute
  top: 2rem

  transition: all $slide-transition-duration ease-in-out
  background-position-x: 0

  img
    height: 100%
    width: 100%
    object-position: right

.outlined-button 
  padding: 0.6rem 1.5rem
  display: flex
  align-items: center
  font-size: 1.5rem 
  font-family: 'Futura PT Demi', sans-serif
  border: 1px solid #808080 
  transition-duration: .5s

  &:hover 
    background-color: $red

.close-button
  width: 47px
  height: 47px
  background-image: url("../static/icons/close-button-icon.svg")
  object-fit: contain
  cursor: pointer
  opacity: 1
  transition-duration: .5s

  &_hidden
    opacity: 0

a //linkhere
  color: white
  :link
    color: white
      
  :visited
    color: white 

.projects-board__pagination .swiper-slide
  height: 100px





